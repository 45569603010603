import { IconContainer } from '../../IconContainer';
import type { IconCommonProps } from '../../../types';

/**
 * Hwp (16x16) 아이콘
 * @param props
 * @returns
 */
export function HwpFileIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props} viewBox="0 0 16 16">
      <path
        d="M12 0H4C1.79086 0 0 1.79086 0 4V12C0 14.2091 1.79086 16 4 16H12C14.2091 16 16 14.2091 16 12V4C16 1.79086 14.2091 0 12 0Z"
        fill="#5DAFE4"
      />
      <path
        d="M11.26 4.5H8.65003V3.85C8.65003 3.49 8.36003 3.2 8.00003 3.2C7.64003 3.2 7.35003 3.49 7.35003 3.85V4.5H4.74003C4.38003 4.5 4.09003 4.79 4.09003 5.15C4.09003 5.51 4.38003 5.8 4.74003 5.8H11.26C11.62 5.8 11.91 5.51 11.91 5.15C11.91 4.79 11.62 4.5 11.26 4.5Z"
        fill="white"
      />
      <path
        d="M8.00002 6.23999C6.29002 6.23999 4.96002 7.18999 4.96002 8.40999C4.96002 9.62999 6.30002 10.58 8.00002 10.58C9.70002 10.58 11.04 9.62999 11.04 8.40999C11.04 7.18999 9.70002 6.23999 8.00002 6.23999ZM8.00002 9.27999C7.02002 9.27999 6.26002 8.80999 6.26002 8.40999C6.26002 8.00999 7.02002 7.53999 8.00002 7.53999C8.98002 7.53999 9.74002 8.00999 9.74002 8.40999C9.74002 8.80999 8.98002 9.27999 8.00002 9.27999Z"
        fill="white"
      />
      <path
        d="M8 13.2C8.48049 13.2 8.87 12.8105 8.87 12.33C8.87 11.8495 8.48049 11.46 8 11.46C7.51952 11.46 7.13 11.8495 7.13 12.33C7.13 12.8105 7.51952 13.2 8 13.2Z"
        fill="white"
      />
    </IconContainer>
  );
}

export default HwpFileIcon;
