import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../../types';

/**
 * ExclamationCircle (16x16) 아이콘
 * @param props
 * @returns
 */
export function ExclamationCircleIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props} viewBox="0 0 14 15">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.99998 2.7751C5.74683 2.7751 4.54501 3.27291 3.6589 4.15902C2.77279 5.04513 2.27498 6.24695 2.27498 7.5001C2.27498 8.12059 2.39719 8.73501 2.63464 9.30828C2.8721 9.88154 3.22014 10.4024 3.6589 10.8412C4.09765 11.2799 4.61853 11.628 5.1918 11.8654C5.76506 12.1029 6.37948 12.2251 6.99998 12.2251C7.62047 12.2251 8.23489 12.1029 8.80815 11.8654C9.38142 11.628 9.9023 11.2799 10.3411 10.8412C10.7798 10.4024 11.1279 9.88154 11.3653 9.30828C11.6028 8.73501 11.725 8.12059 11.725 7.5001C11.725 6.24695 11.2272 5.04513 10.3411 4.15902C9.45494 3.27291 8.25312 2.7751 6.99998 2.7751ZM2.91643 3.41656C3.99946 2.33353 5.46835 1.7251 6.99998 1.7251C8.5316 1.7251 10.0005 2.33353 11.0835 3.41656C12.1665 4.49958 12.775 5.96847 12.775 7.5001C12.775 8.25848 12.6256 9.00944 12.3354 9.71009C12.0452 10.4107 11.6198 11.0474 11.0835 11.5836C10.5473 12.1199 9.91063 12.5453 9.20997 12.8355C8.50932 13.1257 7.75836 13.2751 6.99998 13.2751C6.24159 13.2751 5.49063 13.1257 4.78998 12.8355C4.08932 12.5453 3.45269 12.1199 2.91643 11.5836C2.38018 11.0474 1.95479 10.4107 1.66457 9.71009C1.37435 9.00944 1.22498 8.25848 1.22498 7.5001C1.22498 5.96847 1.83341 4.49958 2.91643 3.41656ZM6.99998 5.2251C7.28992 5.2251 7.52497 5.46015 7.52497 5.7501V7.9376C7.52497 8.22755 7.28992 8.4626 6.99998 8.4626C6.71003 8.4626 6.47498 8.22755 6.47498 7.9376V5.7501C6.47498 5.46015 6.71003 5.2251 6.99998 5.2251ZM6.47498 9.6876C6.47498 9.39765 6.71003 9.1626 6.99998 9.1626H7.00464C7.29459 9.1626 7.52964 9.39765 7.52964 9.6876V9.69226C7.52964 9.98221 7.29459 10.2173 7.00464 10.2173H6.99998C6.71003 10.2173 6.47498 9.98221 6.47498 9.69226V9.6876Z"
        fill="currentColor"
      />
    </IconContainer>
  );
}

export default ExclamationCircleIcon;
