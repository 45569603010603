import { IconContainer } from '../../IconContainer';
import type { IconCommonProps } from '../../../types';

/**
 * Img (16x16) 아이콘
 * @param props
 * @returns
 */
export function ImgFileIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props} viewBox="0 0 16 16">
      <path
        d="M12 0H4C1.79086 0 0 1.79086 0 4V12C0 14.2091 1.79086 16 4 16H12C14.2091 16 16 14.2091 16 12V4C16 1.79086 14.2091 0 12 0Z"
        fill="#128DFF"
      />
      <path
        d="M13.31 11.0911L10.79 6.90576C10.55 6.50621 9.93996 6.50621 9.69996 6.90576L8.95996 8.1344L10.5 10.7615C10.72 11.1411 10.74 11.6005 10.58 12.0001H12.77C13.25 12.0001 13.56 11.5007 13.31 11.1011V11.0911Z"
        fill="white"
      />
      <path
        d="M8.43998 9.00329L6.65998 5.95667C6.55998 5.77687 6.37998 5.69696 6.19998 5.69696C6.01998 5.69696 5.83998 5.78686 5.73998 5.95667L2.66998 11.2008C2.46998 11.5505 2.71998 11.99 3.12998 11.99H9.25998C9.66998 11.99 9.92998 11.5505 9.71998 11.2008L8.42998 9.00329H8.43998Z"
        fill="white"
      />
      <path
        d="M9.35 5.697C10.0956 5.697 10.7 5.09326 10.7 4.3485C10.7 3.60374 10.0956 3 9.35 3C8.60442 3 8 3.60374 8 4.3485C8 5.09326 8.60442 5.697 9.35 5.697Z"
        fill="white"
      />
    </IconContainer>
  );
}

export default ImgFileIcon;
