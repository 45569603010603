import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../../types';

/**
 * Remove (16x16) 아이콘
 * @param props
 * @returns
 */
export function RemoveIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props} viewBox="0 0 16 16">
      <path
        d="M15.0002 0.999939L1.00024 14.9999"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.00024 0.999939L15.0002 14.9999"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconContainer>
  );
}

export default RemoveIcon;
