import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../../types';

/**
 * ArrowDown (16x16) 아이콘
 * @param props
 * @returns
 */
export function ArrowDownIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props} viewBox="0 0 16 16">
      <path
        d="M8 3V13M8 13L12.5 8.5M8 13L3.5 8.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconContainer>
  );
}

export default ArrowDownIcon;
