import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  ArrowRightIcon,
  DeviceMobileSolidIcon,
} from '@dop-ui/icons/react/dop/24';
import { Button } from '../../../../../shared/ui/button';
import * as Dialog from '../../../../../shared/ui/dialog';
import { useTranslation } from '../../../../../shared/lib/i18n/client/use-translation';
import { TfaMobileRegisterGuideSvg } from '../../../../../shared/ui/svg/tfa-mobile-register-guide';
import { QUERY_KEY_TFA_TOOLS } from '../../../api/tfa-get-tfa-tools';

import styles from '../tfa-email-register-dialog/styles/dialog.module.css';

interface Props {
  onClickConfirm?: () => void;
}

export function TfaMobileRegisterDialog({ onClickConfirm }: Props) {
  const { t } = useTranslation('common');
  const queryClient = useQueryClient();

  const [open, setOpen] = useState(false);

  const invalidateAndClose = async () => {
    await queryClient.invalidateQueries({ queryKey: [QUERY_KEY_TFA_TOOLS] });
    setOpen(false);
    onClickConfirm && onClickConfirm();
  };

  const onClickComplete = () => {
    void invalidateAndClose();
  };

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger className={styles.DialogTrigger}>
        <div className="size-[60px] bg-[#EEF1F7] rounded-full flex items-center justify-center">
          <DeviceMobileSolidIcon size={36} className="fill-white" />
        </div>
        <div className="mt-[16px] min-w-[144px]">
          <p className="text-[#363636] text-[16px] font-[600] text-start">
            {t('auth.tfa.tool.mobile')}
          </p>
          <p className="w-full mt-[4px] text-[#363636] text-[13px] font-[400] text-wrap text-start">
            {t('auth.tfa.selectTfaToolDesc.mobile.short')}
          </p>
        </div>
        <ArrowRightIcon size={20} className="text-[#AAAAAA]" />
      </Dialog.Trigger>
      <Dialog.Content
        className={styles.DialogContent}
        useOverlay
        usePortal
        useAutoClose={false}
      >
        <p className="w-full font-[600] text-[20px] text-start text-[#363636]">
          {t('auth.tfa.selectTfaToolTitle')}
        </p>
        <p className="mt-[16px] w-full font-[400] text-[14px] text-start text-[#363636] whitespace-pre-wrap">
          {t('auth.tfa.selectTfaToolDesc.mobile')}
        </p>
        <TfaMobileRegisterGuideSvg className="mt-[16px]" />
        <div className="w-full mt-[24px] flex gap-[16px] justify-end">
          <Button
            size="medium"
            variant="ghost"
            colorset="level1"
            shape="rect"
            onClick={() => {
              setOpen(false);
            }}
          >
            {t('dialog.cancel')}
          </Button>
          <Button
            size="medium"
            variant="solid"
            colorset="level1"
            shape="rect"
            onClick={onClickComplete}
          >
            {t('auth.tfa.goToDoTfa')}
          </Button>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
}

export default TfaMobileRegisterDialog;
