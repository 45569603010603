import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../../types';

/**
 * AdminTable Page Indicator 이전 페이지로 이동하는 (16x16) 아이콘
 * @param props
 * @returns
 */
export function PreviousIndicatorIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4243 2.57574C11.6586 2.81005 11.6586 3.18995 11.4243 3.42427L6.84853 8L11.4243 12.5757C11.6586 12.8101 11.6586 13.19 11.4243 13.4243C11.1899 13.6586 10.8101 13.6586 10.5757 13.4243L5.57574 8.42427C5.34142 8.18995 5.34142 7.81005 5.57574 7.57574L10.5757 2.57574C10.8101 2.34142 11.1899 2.34142 11.4243 2.57574Z"
        fill="currentColor"
      />
    </IconContainer>
  );
}

export default PreviousIndicatorIcon;
