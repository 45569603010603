import { IconContainer } from '../IconContainer';
import { IconCommonProps } from '../../types';

/**
 * 메뉴 접기 아이콘
 * @param props
 * @returns
 */

export function UnFlipIcon(props: IconCommonProps) {
  return (
    <IconContainer viewBox="0 0 14 15" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.62838 4.46627C6.8334 4.26124 7.16582 4.26124 7.37084 4.46627L11.7458 8.84127C11.9509 9.04629 11.9509 9.37871 11.7458 9.58373C11.5408 9.78876 11.2084 9.78876 11.0034 9.58373L6.99961 5.57996L2.99584 9.58373C2.79082 9.78876 2.4584 9.78876 2.25338 9.58373C2.04835 9.37871 2.04835 9.04629 2.25338 8.84127L6.62838 4.46627Z"
        fill="#AAAAAA"
      />
    </IconContainer>
  );
}

export default UnFlipIcon;
