import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../../types';

/**
 * 오픈북 아이콘
 * @param props
 * @returns
 */
export function UnfoldIcon(props: IconCommonProps) {
  return (
    <IconContainer size={18} viewBox="0 0 18 18" {...props}>
      <path
        d="M11.8125 14.625L6.1875 9L11.8125 3.375"
        stroke="#333333"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconContainer>
  );
}

export default UnfoldIcon;
