import { IconContainer } from '../../IconContainer';
import type { IconCommonProps } from '../../../types';

/**
 * Zip (16x16) 아이콘
 * @param props
 * @returns
 */
export function ZipFileIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props} viewBox="0 0 16 16">
      <path
        d="M12 0H4C1.79086 0 0 1.79086 0 4V12C0 14.2091 1.79086 16 4 16H12C14.2091 16 16 14.2091 16 12V4C16 1.79086 14.2091 0 12 0Z"
        fill="#F3BB41"
      />
      <path
        d="M10.41 1.32H9.1C8.74 1.32 8.44 1.03 8.44 0.66C8.44 0.29 8.73 0 9.1 0H10.41C10.77 0 11.07 0.29 11.07 0.66C11.07 1.03 10.78 1.32 10.41 1.32Z"
        fill="white"
      />
      <path
        d="M6.91 2.62913H5.6C5.24 2.62913 4.94 2.33913 4.94 1.96913C4.94 1.59913 5.23 1.30913 5.6 1.30913H6.91C7.27 1.30913 7.57 1.59913 7.57 1.96913C7.57 2.33913 7.28 2.62913 6.91 2.62913Z"
        fill="white"
      />
      <path
        d="M10.41 3.94801H9.1C8.74 3.94801 8.44 3.65801 8.44 3.28801C8.44 2.91801 8.73 2.62801 9.1 2.62801H10.41C10.77 2.62801 11.07 2.91801 11.07 3.28801C11.07 3.65801 10.78 3.94801 10.41 3.94801Z"
        fill="white"
      />
      <path
        d="M6.91 5.25713H5.6C5.24 5.25713 4.94 4.96713 4.94 4.59713C4.94 4.22713 5.23 3.93713 5.6 3.93713H6.91C7.27 3.93713 7.57 4.22713 7.57 4.59713C7.57 4.96713 7.28 5.25713 6.91 5.25713Z"
        fill="white"
      />
      <path
        d="M10.41 6.56625H9.1C8.74 6.56625 8.44 6.27625 8.44 5.90625C8.44 5.53625 8.73 5.24625 9.1 5.24625H10.41C10.77 5.24625 11.07 5.53625 11.07 5.90625C11.07 6.27625 10.78 6.56625 10.41 6.56625Z"
        fill="white"
      />
      <path
        d="M6.91 7.88538H5.6C5.24 7.88538 4.94 7.59538 4.94 7.22538C4.94 6.85538 5.23 6.56538 5.6 6.56538H6.91C7.27 6.56538 7.57 6.85538 7.57 7.22538C7.57 7.59538 7.28 7.88538 6.91 7.88538Z"
        fill="white"
      />
      <g opacity="0.99">
        <path
          d="M4.94 9.19339H11.06V12.2512C11.06 13.2205 10.28 14 9.31 14H6.69C5.72 14 4.94 13.2205 4.94 12.2512V9.19339Z"
          fill="white"
        />
      </g>
    </IconContainer>
  );
}

export default ZipFileIcon;
