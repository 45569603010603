import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../../types';

/**
 * 쓰레기통 (16x16) 아이콘
 * @param props
 * @returns
 */
export function TrashCanIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.49984 2.66667C5.49984 2.02233 6.02217 1.5 6.6665 1.5H9.33317C9.9775 1.5 10.4998 2.02233 10.4998 2.66667V4.16667H12.6613C12.6644 4.16664 12.6675 4.16664 12.6706 4.16667H13.3332C13.6093 4.16667 13.8332 4.39052 13.8332 4.66667C13.8332 4.94281 13.6093 5.16667 13.3332 5.16667H13.1321L12.587 12.7973C12.5185 13.7567 11.7202 14.5 10.7583 14.5H5.24133C4.27949 14.5 3.48118 13.7567 3.41266 12.7973L2.86761 5.16667H2.6665C2.39036 5.16667 2.1665 4.94281 2.1665 4.66667C2.1665 4.39052 2.39036 4.16667 2.6665 4.16667H3.32904C3.33214 4.16664 3.33524 4.16664 3.33834 4.16667H5.49984V2.66667ZM3.87016 5.16667L4.41011 12.726C4.44126 13.1621 4.80413 13.5 5.24133 13.5H10.7583C11.1955 13.5 11.5584 13.1621 11.5896 12.726L12.1295 5.16667H3.87016ZM9.49984 4.16667H6.49984V2.66667C6.49984 2.57462 6.57446 2.5 6.6665 2.5H9.33317C9.42522 2.5 9.49984 2.57462 9.49984 2.66667V4.16667ZM6.6665 6.83333C6.94265 6.83333 7.1665 7.05719 7.1665 7.33333V11.3333C7.1665 11.6095 6.94265 11.8333 6.6665 11.8333C6.39036 11.8333 6.1665 11.6095 6.1665 11.3333V7.33333C6.1665 7.05719 6.39036 6.83333 6.6665 6.83333ZM9.33317 6.83333C9.60931 6.83333 9.83317 7.05719 9.83317 7.33333V11.3333C9.83317 11.6095 9.60931 11.8333 9.33317 11.8333C9.05703 11.8333 8.83317 11.6095 8.83317 11.3333V7.33333C8.83317 7.05719 9.05703 6.83333 9.33317 6.83333Z"
        fill="currentColor"
      />
    </IconContainer>
  );
}

export default TrashCanIcon;
