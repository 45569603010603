import { fetch } from '../../../shared/lib/fetch/client';
import { TwoFactorAuthTools } from '../types';
import { TFA_TOOLS_API_URL } from '../model/tfa-api-urls';

interface TwoFactorAuthToolsResponse {
  data: TwoFactorAuthTools;
}

export const QUERY_KEY_TFA_TOOLS = 'QueryKey.Tfa.TfaTools';

export async function getTfaTools(): Promise<TwoFactorAuthTools> {
  const response = await fetch(TFA_TOOLS_API_URL, {
    headers: {
      'X-Referer-Info': window.location.host,
    },
  });
  const responseData = (await response.json()) as TwoFactorAuthToolsResponse;
  return responseData.data;
}
