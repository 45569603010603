import { IconContainer } from '../../IconContainer';
import type { IconCommonProps } from '../../../types';

/**
 * Doc (16x16) 아이콘
 * @param props
 * @returns
 */
export function DocFileIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props} viewBox="0 0 16 16">
      <path
        d="M12 0H4C1.79086 0 0 1.79086 0 4V12C0 14.2091 1.79086 16 4 16H12C14.2091 16 16 14.2091 16 12V4C16 1.79086 14.2091 0 12 0Z"
        fill="#5169C4"
      />
      <path
        d="M12.5013 4.04741C12.1103 3.91754 11.6792 4.13733 11.5489 4.52697L10.1354 8.84294L8.72181 4.52697C8.72181 4.52697 8.71179 4.497 8.70176 4.487C8.69174 4.45703 8.68171 4.43705 8.67169 4.40708C8.66166 4.3871 8.65163 4.36712 8.63158 4.34714C8.62156 4.32715 8.60151 4.30717 8.58146 4.28719C8.57143 4.26721 8.55138 4.25722 8.54136 4.23724C8.52131 4.21726 8.50126 4.19727 8.48121 4.17729C8.46116 4.1673 8.45113 4.15731 8.43108 4.13733C8.41103 4.11735 8.39098 4.10736 8.36091 4.09737C8.34086 4.08738 8.3208 4.07739 8.29073 4.0674C8.27068 4.0674 8.26065 4.04741 8.2406 4.04741C8.2406 4.04741 8.23058 4.04741 8.22055 4.04741C8.19048 4.04741 8.17043 4.03742 8.14035 4.02743C8.1203 4.02743 8.09023 4.01744 8.07018 4.01744C8.05013 4.01744 8.02005 4.01744 8 4.01744C7.97995 4.01744 7.94987 4.01744 7.92982 4.01744C7.90977 4.01744 7.88972 4.01744 7.85965 4.02743C7.82957 4.02743 7.80952 4.02743 7.77945 4.04741C7.77945 4.04741 7.77945 4.04741 7.76942 4.04741C7.74937 4.04741 7.73935 4.0674 7.7193 4.0674C7.69925 4.0674 7.66917 4.08738 7.64912 4.09737C7.62907 4.10736 7.60902 4.12734 7.58897 4.13733C7.56892 4.14732 7.54887 4.1673 7.52882 4.17729C7.50877 4.19727 7.48872 4.20727 7.47869 4.22725C7.45864 4.24723 7.44862 4.25722 7.42857 4.2772C7.40852 4.29718 7.39849 4.31716 7.38847 4.33714C7.37844 4.35713 7.35839 4.37711 7.34837 4.39709C7.33834 4.41707 7.32832 4.44704 7.31829 4.46702C7.31829 4.487 7.29824 4.497 7.29824 4.51698L5.88469 8.83295L4.47115 4.51698C4.34082 4.12734 3.91977 3.90754 3.51876 4.03742C3.12778 4.1673 2.90723 4.58691 3.03755 4.98654L5.16288 11.4805C5.26314 11.7902 5.55386 12 5.87467 12C6.19547 12 6.4862 11.7902 6.58645 11.4805L8 7.16451L9.41355 11.4805C9.5138 11.7902 9.80453 12 10.1253 12C10.4461 12 10.7369 11.7902 10.8371 11.4805L12.9624 4.98654C13.0928 4.5969 12.8722 4.1673 12.4812 4.03742L12.5013 4.04741Z"
        fill="white"
      />
    </IconContainer>
  );
}

export default DocFileIcon;
