import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../../types';

/**
 * ArrowTop (16x16) 아이콘
 * @param props
 * @returns
 */
export function ArrowTopIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props} viewBox="0 0 16 16">
      <path
        d="M3 7L8 2M8 2L13 7M8 2V14"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconContainer>
  );
}

export default ArrowTopIcon;
