export function isValidEmail(email: string): boolean {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailRegex.test(email);
}

export function maskEmail(email: string): string {
  const [localPart, domain] = email.split('@');

  let maskedLocalPart: string;

  if (!localPart || !domain) {
    return email;
  }

  if (localPart.length > 4) {
    maskedLocalPart = localPart.slice(0, 4) + '*'.repeat(localPart.length - 4);
  } else {
    const halfLength = Math.ceil(localPart.length / 2);
    maskedLocalPart =
      localPart.slice(0, halfLength) +
      '*'.repeat(localPart.length - halfLength);
  }

  return `${maskedLocalPart}@${domain}`;
}
