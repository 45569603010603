const endTimeKey = 'tfa.timer.endTime';
const isRunningKey = 'tfa.timer.isRunning';

const getEndTime = () => {
  return localStorage.getItem(endTimeKey);
};

const setEndTime = (endTime: string) => {
  localStorage.setItem(endTimeKey, endTime);
};

const removeEndTime = () => {
  localStorage.removeItem(endTimeKey);
};

const getIsRunning = () => {
  return localStorage.getItem(isRunningKey);
};

const setIsRunning = (isRunning: boolean) => {
  localStorage.setItem(isRunningKey, isRunning.toString());
};

const removeIsRunning = () => {
  localStorage.removeItem(isRunningKey);
};

const clearTimer = () => {
  removeEndTime();
  removeIsRunning();
};

export const timerUtil = {
  getEndTime,
  setEndTime,
  removeEndTime,
  getIsRunning,
  setIsRunning,
  removeIsRunning,
  clearTimer,
};
