import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../../types';

/**
 * Next 아이콘
 * @param props
 * @returns
 */
export function NextIcon(props: IconCommonProps) {
  return (
    <IconContainer size={12} viewBox="0 0 7 12" {...props}>
      <path
        d="M1 1L6 6L1 11"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconContainer>
  );
}

export default NextIcon;
