import { clsx } from 'clsx';
import { ReactElement } from 'react';
import { ClearIcon } from '@dop-ui/icons/react/dop/24';

interface Props {
  className?: string;
  value: string;
  placeholder?: string;
  inputDisabled?: boolean;
  leftItems?: ReactElement;
  rightItems?: ReactElement;
  onChange: (value: string) => void;
  onClear: () => void;
}

export function ClearableInput({
  className,
  value,
  placeholder,
  inputDisabled,
  leftItems,
  rightItems,
  onChange,
  onClear,
}: Props) {
  return (
    <div
      className={clsx(
        'flex items-center h-[40px] px-[12px] border border-solid border-[#D8D8D8] rounded-[8px]',
        className,
        { 'bg-[#EAECEF] text-[#C5C7CA]': inputDisabled },
      )}
    >
      {leftItems}
      <input
        className="flex-grow h-full"
        value={value}
        placeholder={placeholder}
        disabled={inputDisabled}
        onChange={(e) => onChange(e.target.value)}
      />
      {value && (
        <button
          className="size-[24px] text-[#9B9C9E] bg-transparent"
          onClick={onClear}
        >
          <ClearIcon className="bg-transparent" size={17} />
        </button>
      )}
      {rightItems}
    </div>
  );
}
