import {
  API_TOKEN_REFRESH_URL,
  HttpStatusCodes,
  ServiceErrorCodes,
} from '../../constants';
import { GatewayError } from './errors/gateway';

interface ResponseBody {
  code: string;
  error?: Error;
  message?: string;
}

export default async function reqGatewayApi(
  url: string,
  requestInit?: RequestInit,
): Promise<Response> {
  const reqFetch = async () => await fetch(url, requestInit);

  // API Request 진행
  const response = await reqFetch();

  // 정상 응답일 경우 종료
  if (response.ok) {
    return response;
  }

  const responseBody = (await response.json()) as ResponseBody;
  const isRouteError = Boolean(
    ServiceErrorCodes[responseBody.code as keyof typeof ServiceErrorCodes],
  );

  // 토큰 만료 에러는 Gateway에서 보내줌 Gateway 에러가 아닐 시 바로 반환
  if (!isRouteError) {
    return createHttpResponse(response.status, {
      code: responseBody.code,
      message: responseBody.message,
    });
  }

  // 토큰 만료 에러일 시 갱신로직 진행 및 원래 API 로직 실행
  if (
    response.status === HttpStatusCodes.UNAUTHORIZED &&
    responseBody.code === ServiceErrorCodes['ROUTE-0006'].code
  ) {
    console.info(`[Info] 액세스 토큰이 만료되어 갱신 요청함.`);
    try {
      const resp = await refreshTokens();

      if (!resp.ok) {
        // 토큰 만료 오류를 외부에 전달한다.
        return createHttpResponse(HttpStatusCodes.UNAUTHORIZED, {
          code: ServiceErrorCodes['ROUTE-0006'].code,
          message: ServiceErrorCodes['ROUTE-0006'].message,
        });
      }

      console.info(`[Info] 액세스 토큰 갱신이 성공하여 API 요청을 재수행함.`);
      return await reqFetch();
    } catch (error) {
      // 기타 오류로 처리
      return createHttpResponse(HttpStatusCodes.INTERNAL_SERVER_ERROR, {
        code: 'INTERNAL_SERVER_ERROR',
        error: error as Error,
      });
    }
  }

  // 다른 Gateway 에러일 시 에러처리 필요
  return createHttpResponse(response.status, {
    code: responseBody.code,
    message: responseBody.message,
    error: new GatewayError(
      responseBody.message ? responseBody.message : '',
      responseBody.code,
    ),
  });
}

// 토큰 갱신 로직
async function refreshTokens() {
  const response = await fetch(API_TOKEN_REFRESH_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response;
}

const createHttpResponse = (statusCode: number, body: ResponseBody) => {
  return new Response(JSON.stringify(body), {
    status: statusCode,
  });
};
