import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../../types';

/**
 * AdminTable Page Indicator 다음 페이지로 이동하는 (16x16) 아이콘
 * @param props
 * @returns
 */
export function NextIndicatorIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.57573 2.57574C5.81004 2.34142 6.18994 2.34142 6.42426 2.57574L11.4243 7.57574C11.6586 7.81005 11.6586 8.18995 11.4243 8.42427L6.42426 13.4243C6.18994 13.6586 5.81004 13.6586 5.57573 13.4243C5.34142 13.19 5.34142 12.8101 5.57573 12.5757L10.1515 8L5.57573 3.42427C5.34142 3.18995 5.34142 2.81005 5.57573 2.57574Z"
        fill="currentColor"
      />
    </IconContainer>
  );
}

export default NextIndicatorIcon;
