import { gql } from 'graphql-request';

export const ID = 'userSessionQuery';

export const Query = gql`
  query ${ID} {
    me {
      id
      name
      loginId
      company{
        id
        companyGroupId
        uuid
      }
      email
      status
      employeeNumber
      company {
        id
        companyGroupId
        companyAppConfig {
          GNB_PERSONAL_CONTROL
        }
      }
      mainDuty {
        name
      }
      mainDepartment {
        name
        emailId
        departmentPath
        alias
      }
      departments {
        id
        name
        alias
        code
        departmentPath
        emailId
        sortOrder
      }
      isAccessibleBusinessPortal
      isAccessibleSettingPortal
      locale
      userInfo {
        profileImageUrl
        grade {
          name
        }
      }
      accessibleAppCodeList {
        appCategory
        appDescription
        appId
        appName
        code
        companyAppName
        isWebPermit
        name
        portalType
        id
      }
    }
  }
`;
