import { fetch } from '../../../shared/lib/fetch/client';
import { ResponseBody } from '../types';
import { MAIL_TFA_TOOL_API_URL } from '../model/tfa-api-urls';

export async function registerMail(enrollCode: string, mailAddress: string) {
  const response = await fetch(MAIL_TFA_TOOL_API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ enrollCode, mailAddress }),
  });

  if (!response.ok) {
    const responseData = (await response.json()) as ResponseBody<string>;
    throw new Error(responseData.message);
  }
}
