import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../../types';

/**
 * Check (16x16) 아이콘
 * @param props
 * @returns
 */
export function CheckIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props} viewBox="0 0 16 16">
      <path
        d="M14.6666 3.41663L5.49992 12.5833L1.33325 8.41663"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconContainer>
  );
}

export default CheckIcon;
