import { fetch } from '../../../shared/lib/fetch/client';
import type { ResponseBody } from '../types';
import { MAIL_ENROLL_CODE_API_URL } from '../model/tfa-api-urls';

export async function requestMailEnrollCode(mailAddress: string) {
  const response = await fetch(MAIL_ENROLL_CODE_API_URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ mailAddress }),
  });

  if (!response.ok) {
    const responseData = (await response.json()) as ResponseBody<string>;
    throw new Error(responseData.message);
  }
}
